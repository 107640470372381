import React, { useEffect, useState, Suspense, lazy } from "react";
import "./index.scss";
import { Button, Collapse } from "antd";
import { faqlist } from "./faq";
import logo from "./images/logo.svg";
import trade from "./images/trade.svg";
import learn from "./images/learn.svg";
import links from "./images/links.svg";
import linksmini from "./images/links-mini.svg";
import light from "./images/light.svg";
import booking from "./images/booking.svg";
import bookingmini from "./images/bookingmini.svg";
import p1 from "./images/p1.svg";
import p2 from "./images/p2.svg";
import share from "./images/share.svg";
import sufuring from "./images/sufuring.svg";
import x from "./images/x.svg";
import xicon from "./images/xicon.svg";
import tg from "./images/tg.svg";
import tgicon from "./images/tgicon.svg";
import audit from "./images/audit.svg";
import arrow from "./images/arrow.svg";
import youtube from "./images/youtube.svg";
import coming from "./images/coming.svg";
import why1 from "./images/why1.svg";
import why2 from "./images/why2.svg";
import outline from "./images/outline.svg";
import outline2 from "./images/outline2.svg";
import partbtn from "./images/part-btn.svg";
import qie from "./images/qie.svg";
import shadow from "./images/shadow1.svg";
import shadow2 from "./images/shadow2.svg";
import lighty from "./images/lighty.svg";
import lightb from "./images/lightb.svg";
import arrowy from "./images/arrowy.svg";
import arrowb from "./images/arrowb.svg";
import soon from "./images/soon.svg";
import move from "./images/move.gif";

const Step = lazy(() => import("../components/Step"));

export default () => {
  const [fixed, setFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      setFixed(scrollTop > 100);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="content">
      <div className="first-bg">
        {/* <img src={light} alt="" className="light show-p" /> */}
        {/* header */}
        <div className={`header flex flex-center flex-between p-16 ${fixed ? "fixed" : ""}`}>
          <img src={logo} alt="" className="m-w-90 w-107 h-36" />
          <div className="links flex gap-40 m-gap-12">
            <span className="c230 fz-14 flex flex-center gap-4 m-gap-2">
              <img src={trade} alt="" />
              SUDO.Trade
            </span>
            <span className="c230 fz-14 flex flex-center gap-4 m-gap-2">
              <img src={learn} alt="" />
              Learn
            </span>
            <img src={links} alt="" className="pointer show-p" />
            <img src={linksmini} alt="" className="pointer show-m" />
          </div>
        </div>
        {/* banner */}
        <div className="flex flex-between flex-center gap-120 p-t-36 m-flex-column m-p-16 m-gap-40">
          <div className="flex flex-column c230 flex-start m-flex-center w100 flex-1">
            <div className="fz-60 fwb m-fz-30 m-ta">WYSIWYG</div>
            <div className="fz-36 fwb tl up m-t-10 m-fz-18 m-ta">
              Lightning-fast on-chain <br /> transaction experience
            </div>
            <div className="fz-26 tl op-08 m-t-20 m-fz-16 m-ta">
              Say goodbye to complicated and tedious Telegram Bot interaction instructions, Sudo.Trade
              brings you an intuitive and easy-to-use trading experience.
            </div>
            <Button className="color-btn c0 flex flex-center p-t-10 p-b-10 p-l-20 p-r-20 h-52 bdr-52 fwb gap-4 pointer m-t-44 m-m-t-20">
              Launch SUDO.Trade
              <img className="w-55 h-20" src={soon} alt="" />
            </Button>
          </div>
          <div className="move m-w50"></div>
          {/* <img src={move} alt="" className="move m-w50"  /> */}
        </div>
      </div>
      {/* booking */}
      <div className="booking m-t-65 m-m-t-30 p-15">
        {/* <img src={booking} alt="" className="bookingbg show-p w100" loading="lazy" />
        <img src={bookingmini} alt="" className="bookingbg show-m w100" loading="lazy" /> */}
        <div className="book-area flex flex-column flex-center flex-middle gap-30 m-gap-20 m-w100 m-p-20">
          <div className="fz-36 fwb c230 up ta m-fz-24">Booking Experience</div>
          <div className="fz-24 c230 ta op-08">
            Book a Sudo.trade appointment and get early access.
          </div>
          <div className="flex flex-center m-t-15 m-flex-column gap-20 m-gap-10">
            <Button
              className="color-btn c0 flex flex-center p-t-10 p-b-10 p-l-20 p-r-20 h-52 bdr-52 fwb gap-4 pointer"
              onClick={() => {
                window.open("https://twitter.com/intent/follow?screen_name=sudotrade");
              }}
            >
              Follow  SUDO.Trade on
              <img src={xicon} alt="" />
            </Button>
            <Button className="color-btn-hollow c0 flex flex-center p-t-10 p-b-10 p-l-20 p-r-20 h-52 bdr-52 fwb pointer flex-middle">
              <div className="color-btn-text gap-4 flex">
                <span className="color-text">Subscribe to  SUDO.Trade</span>
                <img src={tgicon} alt="" />
              </div>
            </Button>
          </div>
        </div>
        <div className="step">
          <Suspense fallback={<div>Loading...</div>}>
            <Step />
          </Suspense>
        </div>
      </div>
      {/* product */}
      <div className="m-p-15">
        <div className="fz-36 fwb c230 up tl m-t-120 m-fz-24 m-m-t-40">Product</div>
        <div className="fz-24 c230 op-08 tl m-t-15">
          Book a Sudo.trade appointment and get early access.
        </div>
        <div className="flex flex-start gap-36 p-t-56 m-flex-column">
          <div className="product-img">
            {/* <img src={p1} alt="" className="blur m-w100" loading="lazy" /> */}
            <img src={coming} alt="" className="coming m-w50" loading="lazy" />
          </div>
          <div className="product-img">
            {/* <img src={p2} alt="" className="blur m-w100" loading="lazy" /> */}
            <img src={coming} alt="" className="coming m-w50" loading="lazy" />
          </div>
        </div>
      </div>
      {/* why */}
      <div className="fz-36 fwb c230 up ta m-t-120 m-fz-24">
        Why SUDO.Trade is your best <br />
        trading partner
      </div>
      <div className="fz-24 c230 op-08 ta m-t-30 desc">
        Experience the superior performance of our advanced technology, ensuring you stay ahead in
        every transaction. Try our services and feel the difference firsthand.
      </div>
      <div className="why-parts">
        <div className="flex flex-center gap-24 flex-middle m-t-30 m-flex-column p-15">
          <div className="part1 part m-w100">
            {/* <img src={outline} alt="" className="outline show-p" loading="lazy" /> */}
            {/* <img src={outline2} alt="" className="outline show-m" loading="lazy" /> */}
            <div className="part-content">
              <div className="c230 up fz-18 fwb tl p-24">
                Comprehensive trading assistance functions
              </div>
              <img src={why1} alt="" className="show-p" loading="lazy" />
              <img src={why2} alt="" className="show-m w100" loading="lazy" />
              <img src={partbtn} alt="" className="part-btn" loading="lazy" />
            </div>
          </div>
          <div className="part2 part">
            {/* <img src={qie} alt="" className="outline" loading="lazy" /> */}
            <div className="part-content">
              <div className="c230 up fz-18 fwb ta p-t-24">
                Comprehensive trading assistance functions
              </div>
              <div className="light-area">
                <img src={shadow} alt="" loading="lazy" />
                <img src={lighty} alt="" className="light-img" loading="lazy" />
              </div>
              <div className="arrow-area">
                <img src={arrowy} alt="" loading="lazy" />
                <div className="ta fz-14 cy up m-t-10">Practical & intuitive</div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-center gap-24 flex-middle m-t-24 m-flex-column">
          <div className="part2 part">
            {/* <img src={qie} alt="" className="outline" loading="lazy" /> */}
            <div className="part-content">
              <div className="c230 up fz-18 fwb ta p-t-24">
                Lightning-fast price <br /> updates
              </div>
              <div className="light-area">
                <img src={shadow2} alt="" loading="lazy" />
                <img src={lightb} alt="" className="light-img" loading="lazy" />
              </div>
              <div className="arrow-area">
                <img src={arrowb} alt="" loading="lazy" />
                <div className="ta fz-14 cb up m-t-10">Fastest Swap Execution</div>
              </div>
            </div>
          </div>
          <div className="part1 part">
            {/* <img src={outline} alt="" className="outline show-p" loading="lazy" /> */}
            {/* <img src={outline2} alt="" className="outline show-m" loading="lazy" /> */}
            <div className="part-content">
              <div className="c230 up fz-18 fwb tl p-24">
                Filter information that is valuable to you from multiple angles
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="p-16 w100 m-t-80 m-m-t-40 join">
        <div className="join-content flex flex-column flex-center m-t-100 w100 m-m-t-60">
          <div className="fz-36 fwb c230 ta up m-fz-28">
            Join SUDO.Trade's <br />
            Top referral program
          </div>
          <div className="fz-24 c230 up m-t-40 m-m-t-20">Increase your passive income</div>
          <div className="flex flex-center flex-middle gap-20 m-t-40 m-m-t-20 m-flex-column">
            <div className="linear-btn c230 h-42 lh-42 bdr-21 p-l-20 p-r-20">
              <span className="op-08">✨Get up to 35% referral bonus</span>
            </div>
            <div className="linear-btn c230 h-42 lh-42 bdr-21 p-l-20 p-r-20">
              <span className="op-08">Save 10% on SUDO.Trade Fees</span>
            </div>
          </div>
          <div className="yellow-btn lh-25 p-t-8 p-b-8 bdr-21 p-l-20 p-r-20 m-t-20">
            💛Accumulate 5 layers of referrals. Earn from your referral's referrals! 💛
          </div>
          <div className="flex flex-center gap-20 m-t-180 m-m-t-80 m-flex-column">
            <div className="color-btn h-52 lh-52 bdr-52 fwb p-l-40 p-r-40 m-w100">
              Claim your invitation link {">"}
            </div>
            <Button className="color-btn-hollow c0 flex flex-center m-w100 p-t-10 p-b-10 p-l-40 p-r-40 h-52 bdr-52 fwb pointer flex-middle">
              <div className="color-btn-text gap-4 flex">
                <span className="color-text">Learn more</span>
                <img src={share} alt="" />
              </div>
            </Button>
          </div>
        </div>
        {/* <img src={sufuring} alt="" loading="lazy" /> */}
      </div>
      {/* faq */}
      <div className="flex flex-between p-t-140 m-flex-column">
        <div className="fz-36 fwb c230 tl m-ta m-fz-24">
          SUDO.Trade Asked & <br />
          Questions
        </div>
        <div className="flex flex-column m-p-16">
          <Collapse
            expandIconPosition="end"
            expandIcon={({ isActive }) => (
              <img
                src={arrow}
                style={{
                  color: "#fff",
                  marginTop: "5px",
                  transform: isActive ? "rotate(90deg)" : "rotate(0deg)",
                }}
              />
            )}
            bordered={false}
            style={{ background: "transparent", color: "#fff" }}
            items={faqlist}
          />
        </div>
      </div>
      {/* footer */}
      <div className="w100 footer flex flex-center flex-between p-t-68 p-b-68 m-p-b-40 m-flex-column-reverse m-gap-30">
        <div>
          <div className="flex flex-center gap-10">
            <a href="https://x.com/sudotrade" target="_blank" rel="noopener noreferrer">
              <img src={x} alt="" />
            </a>
            <a href="/">
              <img src={tg} alt="" />
            </a>
            <a href="/">
              <img src={youtube} alt="" />
            </a>
            <a href="/">
              <img src={audit} alt="" />
            </a>
          </div>
          <div className="tl c230 fz-14 m-t-10 m-ta">© 2024 SUDO.Trade. All rights reserved.</div>
        </div>
        <img src={logo} alt="" className="h-56" />
      </div>
    </div>
  );
};
