export const faqlist = [{
    key: '1',
    label: <span className='c230 m-fz-16'>How much does SUDO.Trade charge to use their bot/tools?</span>,
    children: <div className='c230 m-fz-16'>How much does SUDO.Trade charge to use their bot/tools?How much does SUDO.Trade charge to use their bot/tools?</div>
  },
  {
    key: '2',
    label: <span className='c230 m-fz-16'>What is the minimum amount of SOL needed to start trading with SUDO.Trade  efficiently?</span>,
    children: <div className='c230 m-fz-16'>What is the minimum amount of SOL needed to start trading with SUDO.Trade  efficiently?What is the minimum amount of SOL needed to start trading with SUDO.Trade  efficiently?</div>
  },
  {
    key: '3',
    label: <span className='c230 m-fz-16'>Is  SUDO.Trade safe?</span>,
    children: <div className='c230 m-fz-16'>Is  SUDO.Trade safe?Is  SUDO.Trade safe?</div>
  },
  {
    key: '4',
    label: <span className='c230 m-fz-16'>What slippage and gas settings do you recommend?</span>,
    children: <div className='c230 m-fz-16'>What slippage and gas settings do you recommend?What slippage and gas settings do you recommend?</div>
  },
  {
    key: '5',
    label: <span className='c230 m-fz-16'>Will SUDO.Trade’s services continue if my device is offline?</span>,
    children: <div className='c230 m-fz-16'>Will SUDO.Trade’s services continue if my device is offline?Will SUDO.Trade’s services continue if my device is offline?</div>
  }]